<template>
  <!-- 设备信息 -->
  <div id="app">
    <div class="container">
      <el-row :gutter="15">
        <!-- 日期选择器 -->
        <el-col :span="3">
          <el-date-picker
            v-model="pickerValue"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-col>
        <!-- 查询按钮 -->
        <el-col :span="1.5"><el-button type="primary">查询</el-button></el-col>
        <!-- 导出按钮 -->
        <el-col :span="2">
          <el-button type="primary">导出EXCEL</el-button>
        </el-col>
      </el-row>
      <el-table :data="tableData" height="90%">
        <el-table-column align="center" prop="coding" label="设备编号"></el-table-column>
        <el-table-column align="center" prop="deviceName" label="设备名称"></el-table-column>
        <el-table-column align="center" prop="deviceId" label="IMEI"></el-table-column>
        <el-table-column align="center" prop="value" label="用电量"></el-table-column>
        <el-table-column align="center" prop="U_a" label="A相电压"></el-table-column>
        <el-table-column align="center" prop="U_b" label="B相电压"></el-table-column>
        <el-table-column align="center" prop="U_c" label="C相电压"></el-table-column>
        <el-table-column align="center" prop="I_a" label="A相电流"></el-table-column>
        <el-table-column align="center" prop="I_b" label="B相电流"></el-table-column>
        <el-table-column align="center" prop="I_c" label="C相电流"></el-table-column>
        <el-table-column align="center" prop="gateState" label="闸门状态"></el-table-column>
        <el-table-column align="center" prop="deviceState" label="设备状态"></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  watch: {},
  props: {},
  data() {
    return {
      // 时间日期选择器默认绑定
      pickerValue: '',
      // 表格绑定数据
      tableData: [],
    };
  },
  created() {
    this.setDefaultTime()
    this.getTableData()
  },
  methods: {
    // 设置默认时间
    setDefaultTime(){
      this.pickerValue = this.$moment().format("YYYY-MM-DD")
    },
    // 获取表格数据
    getTableData() {
      this.tableData = []
      // 假数据
      for (let i = 0; i < 20; i++) {
        let state = (Math.random()>0.2)?'正常':'失联'
        let state_1 = (Math.random()>0.7)?'打开':'关闭'
        let obj = {
          deviceName:`模拟设备_${i}`,
          deviceId:`1230000${i}`,
          coding:`a_${i}`,
          value:Math.random().toFixed(2),
          I_a:Math.random().toFixed(2),
          I_b:Math.random().toFixed(2),
          I_c:Math.random().toFixed(2),
          U_a:Math.random().toFixed(2),
          U_b:Math.random().toFixed(2),
          U_c:Math.random().toFixed(2),
          deviceState:state,
          gateState:state_1,
        }
        this.tableData.push(obj)
      }
      console.log(this.tableData);
    },
  },
};
</script>

<style lang="scss" scoped>
// 导入表格css
@import "../../../assets/css/tableCss.scss";
.container {
  color: antiquewhite;
  width: 100%;
  height: 100%;
  padding: 90px 10px 0 0;
  box-sizing: border-box;
  position: relative;
}
/deep/.el-tree-node__content:hover,
.el-upload-list__item:hover {
  background-color: rgba($color: #007a91, $alpha: 0.5);
}
/deep/.el-tree-node:focus > .el-tree-node__content {
  background-color: rgba($color: #007a91, $alpha: 0.5);
}
/deep/.el-picker-panel{
  background: rgba($color: #00ffff, $alpha: 0.7) !important;
}
</style>